import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  "@global": {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    "*:focus": {
      outline: 0,
    },
    ".container": {
      width: "100%",
      padding: theme.spacing(2),
      paddingBottom: 100,
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
