import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import titillumRegularT from "./fonts/titillium-web-v8-latin-regular.ttf";
import titillumRegularW from "./fonts/titillium-web-v8-latin-regular.woff2";
import titillumBoldT from "./fonts/titillium-web-v8-latin-700.ttf";
import titillumBoldW from "./fonts/titillium-web-v8-latin-700.woff2";

const titillium = {
  fontFamily: "Titillium Web",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Titillium Web Regular'),
    local('TitilliumWeb-Regular'),
    url(${titillumRegularW}) format('woff2'),
    url(${titillumRegularT}) format('ttf')
  `,
};

const titilliumBold = {
  fontFamily: "Titillium Web",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
    local('Titillium Web Bold'),
    local('TitilliumWeb-Bold'),
    url(${titillumBoldW}) format('woff2'),
    url(${titillumBoldT}) format('ttf')
  `,
};

// colors
const primary = "#3f51b5";
const secondary = "#3d5afe";
const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const background = "#f5f5f5";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";
const darkBackground = "#e8e8e8";
const gray = "#8b8b8b";
const lightgray = "#f2f0f0";
const anotherBlack = "#333333";
const anotherGray = "#e1e1e1";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    common: {
      black,
      darkBlack,
      anotherBlack,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
      dark: darkBackground,
      shade: anotherGray,
      light: gray,
      muted: lightgray,
    },
    spacing,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [titillium, titilliumBold],
      },
    },
    MuiExpansionPanel: {
      root: {
        position: "static",
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
  },
  typography: {
    fontFamily: [
      '"Titillium Web"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    useNextVariants: true,
    body1: {
      fontSize: 14,
    },
  },
});

export default responsiveFontSizes(theme);
